import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import './scoretable.css'
// const lapTimes = [
//   { playerId: '122', bestLapTimeOverall: 35.49457550048828 },
//   { playerId: '100', bestLapTimeOverall: 39.93475341796875 },
//   { playerId: '51', bestLapTimeOverall: 41.65478515625 },
//   { playerId: '47', bestLapTimeOverall: 47.86834716796875 },
// ];

const LapTimesTable = () => {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api-int.levityrace.com/stats/leaderboard');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        const parsedBody = JSON.parse(result.body);
        setData(parsedBody["Desert Space Port"]);
        // setData(result.body);
        // console.log(result.body)
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
      
      
    };

    fetchData();
  }, []); // Empty dependency array ensures the fetch happens only once

  useEffect(() => {
    if (data) {
      console.log("Updated data state:", data); // Log data when it changes
    }
  }, [data]); // Runs whenever `data` changes

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className='p-10 text-white main__scoretable border-0 '>
      <div className='main__scoretable__textbox'>
      <h1 className='py-4'>Greatest Racers!</h1>
     <div className='border-0 p-2 bg-black bg-opacity-70 rounded-md'>
      <table className='border-0 text-white w-[450px] text-left p-8 rounded-lg'>
        <thead className='p-8'>
          <tr className='bg-black bg-opacity-0 p-8'>
          <th className='py-0 px-2'>Player Name</th>
            <th className='py-0 px-2'>Best Lap Time (s)</th>
          </tr>
        </thead>
        <tbody className='bg-black bg-opacity-0 pb-2 rounded-lg border-0'>
          {Array.isArray(data) ? (
            data.map((lapTime) => (
              <tr key={lapTime.playerName}>
                <td className='py-0 px-4'>{lapTime.playerName}</td>
                <td className=''>{lapTime.bestLapTime.toFixed(2)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
      </div>
    </div>
  );
};

export default LapTimesTable;